import React from "react";
import classNames from "classnames";
import { Carousel, CardSlide } from "@molecules";
import { Container, Text, Button, AnimateIn } from "@atoms";

const CardCarousel = ({
  button,
  cards,
  descriptor,
  heading,
  paddingTop,
  showDivider,
}) => {
  return (
    <section
      className={classNames(
        "relative z-10 w-full overflow-hidden pb-20 md:pb-28",
        {
          "pt-20 md:pt-28": paddingTop,
          "border-t-2 border-black": showDivider,
        }
      )}
    >
      <Container>
        <AnimateIn
          preset="fadeUpFast"
          className="flex flex-wrap gap-6 sm:gap-20"
        >
          <div className="w-full sm:w-1/3 md:w-2/5 md:whitespace-pre-line">
            <Text variant="h2" className="md:max-w-[12ch]">
              {heading}
            </Text>
          </div>
          <div className="flex-1">
            <Text
              className={classNames(
                "block text-2xl leading-tight md:text-3xl",
                {
                  "sm:pt-[.55rem] md:pt-4": heading,
                }
              )}
            >
              {descriptor}
            </Text>
          </div>
        </AnimateIn>
        <div className="mt-12 md:mt-20">
          {cards?.length > 3 ? (
            <Carousel maxVisible={3} indicators className="-mx-4">
              {cards.map(card => {
                return (
                  <div key={card.uid} className="h-full px-4">
                    <CardSlide {...card} />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <div className="flex flex-wrap gap-8">
              {cards.map((card, i) => {
                return (
                  <AnimateIn
                    key={card.uid}
                    preset="fadeUpFast"
                    delay={0.2}
                    threshold={0.2}
                    className="flex-grow sm:flex-[0_1_calc(50%-1.333rem)] lg:flex-[0_1_calc(33.333%-1.333rem)]"
                  >
                    <CardSlide {...card} />
                  </AnimateIn>
                );
              })}
            </div>
          )}
        </div>
        {button?.url && (
          <div className="mt-20 flex items-center justify-center">
            <Button to={button.url}>{button.text}</Button>
          </div>
        )}
      </Container>
    </section>
  );
};

CardCarousel.defaultProps = {
  paddingTop: true,
  showDivider: false,
};

export default CardCarousel;
